import styled, { css } from "styled-components";
import Button from "@components/button";

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  column-gap: 30px;
  row-gap: 20px;

  @media (max-width: 1024px) {
    column-gap: 29px;
    row-gap: 18px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const BottomWrapper = styled.div`
  display: grid;
  grid-template-columns: 2.3fr 1fr;
  column-gap: 30px;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 18px;
  }
`;

export const StyledLabel = styled.p`
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 1024px) {
    margin-top: 10px;
    margin-bottom: 18px;
  }
`;

export const CustomDonationWrapper = styled.div`
  border-radius: 5px;

  height: 38px;
  border: 1px solid #060038;
  display: flex;
  flex-direction: row;
  align-items: center;

  /* border: ${(props) => (!props.isValid ? "red solid 4px" : "none")}; */

  opacity: ${(props) => (props.disabled ? "0.3" : "1.0")};

  ${(props) => {
    if (props.disabled) {
      return css`
        opacity: 0.3;
      `;
    }
  }}

  @media (max-width: 758px) {
    margin: 15px 0;
    padding: 20px 0px;
  }

  #input-example {
    border: none;
    background: transparent;
    width: 100%;
    margin: 0 24px;
  }
`;

export const ConfirmButton = styled(Button)`
  @media (max-width: 1024px) {
    width: 100%;
    height: 49px;
  }
`;

export const InputLabel = styled.label`
  margin: 0 24px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
  flex-shrink: 0;

  @media (max-width: 758px) {
    font-size: 18px;
  }
`;

export const ErrorMessage = styled.div`
  margin: 25px 0 10px 0;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  color: #8b0000;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 768px) {
    margin-bottom: 4px;
  }
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;
