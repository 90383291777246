import PropTypes from "prop-types";
import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import styled from "styled-components";
import useInterval from "@queries/use-interval";

const LoaderWrapper = styled.div`
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div``;

const Subtitle = styled.div`
  margin: 20px 0 30px 0;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

const LoaderExampleInlineCentered = () => (
  <LoaderWrapper>
    <ScaleLoader color="#E43F5A" loading size={150} />
  </LoaderWrapper>
);

const CreditCardComponent = (props) => {
  const [data, setData] = React.useState({});
  const [error, setError] = React.useState(null);
  const [loading] = React.useState(true);

  const fetchMBReference = async () => {
    const timestamp = new Date().getTime();

    try {
      const result = await axios.post("/api/credit-card", {
        amount: props.amount,
        id: timestamp,
        chave: process.env.GATSBY_EUPAGO_API,
      });

      if (result?.data.error) {
        setError(result.data.error);
      } else {
        setData(result.data);
      }
    } catch (error) {
      setError("Ocorreu um erro, por favor tente mais tarde.");
    }
  };

  React.useEffect(() => {
    fetchMBReference();
  }, [props.amount]);

  useInterval(() => {
    if (!error && data?.url) {
      window.location.replace(data?.url);
    }
  }, 3000);

  if (error) {
    return <Subtitle>{error}</Subtitle>;
  }

  return <Container>{loading && <LoaderExampleInlineCentered />}</Container>;
};

CreditCardComponent.defaultProps = {
  amount: 1.0,
};

CreditCardComponent.propTypes = {
  amount: PropTypes.number,
};

export default CreditCardComponent;
