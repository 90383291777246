import {
  BottomWrapper,
  ButtonsWrapper,
  ConfirmButton,
  CustomDonationWrapper,
  ErrorMessage,
  InputLabel,
  Paragraph,
  StyledLabel,
  Title,
} from "./styles";
import Button from "@components/button";
import CurrencyInput from "react-currency-input-field";
import PropTypes from "prop-types";
import React from "react";

const renderButton = (onClick = () => {}, isSelected = false, value = 1) => {
  return (
    <Button
      onClick={() => onClick(value)}
      size="extra-large"
      color={isSelected ? "white" : "black"}
      bg={isSelected ? "orange" : "none"}
      border={isSelected ? "none" : "blue"}
    >
      {`${value} €`}
    </Button>
  );
};

const options = ["25", "50", "75", "100", "250", "500"];

const DonationSelector = (props) => {
  const inputRef = React.useRef();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (props.isDonationValid) {
        props?.onConfirm();
      }
    }
  };

  const handleOnValueChange = (value) => {
    props.onCustomDonationChange(value);
  };

  const handlePreSelectedClick = (value, alreadySelected) => {
    props.onPreDefinedDonationChange(alreadySelected ? null : value);
  };

  return (
    <React.Fragment>
      <div>
        <Title>Apoia hoje a treebo</Title>
        <Paragraph>
          O nosso trabalho importa. Ajuda-nos hoje a pavimentar o caminho de
          amanhã.
        </Paragraph>

        <StyledLabel>Com quanto queres contribuir?</StyledLabel>
        <ButtonsWrapper>
          {options.map((donationValue) => {
            return renderButton(
              (value) =>
                handlePreSelectedClick(
                  value,
                  props.selectedPreDefinedDonation === donationValue
                ),
              props.selectedPreDefinedDonation === donationValue,
              donationValue
            );
          })}
        </ButtonsWrapper>
      </div>
      <div>
        <ErrorMessage isVisible={!props.isDonationValid}>
          {props.errorMessage}
        </ErrorMessage>
        <BottomWrapper>
          <CustomDonationWrapper
            isValid={props.isDonationValid}
            disabled={props.selectedPreDefinedDonation !== null}
            onClick={() => {
              inputRef?.current?.focus();
            }}
          >
            <InputLabel for="donation">Outra quantia: </InputLabel>
            <CurrencyInput
              onKeyDown={handleKeyDown}
              id="input-example"
              name="input-name"
              decimalsLimit={2}
              placeholder="insere o valor"
              allowNegativeValue={false}
              ref={inputRef}
              decimalScale={2}
              prefix="€"
              value={props.selectedCustomDonation}
              groupSeparator=","
              disabled={props.selectedPreDefinedDonation !== null}
              disableAbbreviations={true}
              onValueChange={(value) => handleOnValueChange(value)}
            />
          </CustomDonationWrapper>

          <ConfirmButton
            onClick={props.onConfirm}
            size="large"
            color="white"
            bg="orange"
            border="none"
            disabled={!props.isDonationValid}
          >
            contribuir
          </ConfirmButton>
        </BottomWrapper>
      </div>
    </React.Fragment>
  );
};

DonationSelector.propTypes = {
  onConfirm: PropTypes.func,
  selectedPreDefinedDonation: PropTypes.oneOfType([PropTypes.number, null]),
  selectedCustomDonation: PropTypes.oneOfType([PropTypes.string, undefined]),
  isDonationValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  onCustomDonationChange: PropTypes.func,
  onPreDefinedDonationChange: PropTypes.func,
};

export default DonationSelector;
