import Img from "gatsby-image";
import styled from "styled-components";

export const Container = styled.button`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;

  @media (max-width: 768px) {
    width: 100%;
    align-items: start;
  }
`;

export const Card = styled.div`
  position: relative;
  border-radius: 5px;
  width: 160px;
  height: 82px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Radio = styled.input`
  --webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  left: -5px;
  position: absolute;
  cursor: pointer;
  outline: none;
  box-shadow: -3px -3px 10px rgba(2, 28, 53, 0.08);
  border: 1px solid #b4b4b4;

  &::before {
    content: "";
    position: absolute;

    height: 14px;
    width: 14px;
    background-color: #f9fafd;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 20px;
    border: 1px solid #060038;
  }

  &::after {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: transparent;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 20px;
  }

  &:checked {
    border: 1px solid #060038;
  }

  &:checked::after {
    background-color: #060038;
  }
`;

export const Label = styled.p`
  display: none;

  @media (max-width: 768px) {
    display: initial;
    position: absolute;
    left: 60px;
    top: 40%;
    transform-origin: center;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #060038;
  }
`;

export const Media = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
`;

export const StyledImg = styled(Img)`
  width: 45px;
`;

export const Subtitle = styled.div`
  margin-top: 8px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #060038;

  @media (max-width: 768px) {
    display: none;
  }
`;
