import Img from "gatsby-image";
import styled from "styled-components";

export const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
  margin-top: 28px;
  margin-bottom: 50px;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: unset;

    & > * + * {
      margin-top: 40px;
    }
  }
`;

export const Media = styled.div`
  flex: 1;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin: unset;
  }
`;

export const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  max-height: 444px;
  max-width: 444px;
  margin: 0 auto;

  @media (max-width: 768px) {
    height: 100%;
    max-height: 346px;
    max-width: unset;
    picture {
      object-fit: cover;
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  max-width: 540px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    max-width: unset;
  }
`;
