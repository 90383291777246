import "react-phone-input-2/lib/style.css";
import {
  Header,
  Media,
  Middle,
  Navigation,
  Row,
  StyledImg,
  Title,
} from "./styles";
import Button from "@components/button";
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";
import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import pt from "react-phone-input-2/lang/pt.json";
import styled from "styled-components";
import { useImage } from "@queries/use-image";

const Subtitle = styled.div`
  margin: 20px 0 30px 0;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

const MBWayComponent = (props) => {
  const { getFluidImageName } = useImage();
  const [error, setError] = React.useState(null);
  const [isCheckout, setIsCheckout] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(true);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const [reference, setReference] = React.useState({});

  const validateNumber = (value, country) => {
    if (String(value).length <= 3) {
      return true;
    }
    switch (country.iso2) {
      case "pt":
        return value.match("^351[0-9]{9}$") != null;
      default:
        return true;
    }
  };

  const isDisabled = phoneNumber.match("^351[0-9]{9}$") != null;

  const handleClick = React.useCallback(async () => {
    setIsCheckout(true);
    setIsProcessing(true);

    try {
      const timestamp = new Date().getTime();

      const response = await axios.post("/api/mbway-reference", {
        amount: props.amount,
        id: timestamp,
        alias: phoneNumber.replace(countryCode, ""),
        chave: process.env.GATSBY_EUPAGO_API,
      });

      if (response?.data.error) {
        setError(response.data.error);
      } else {
        setIsProcessing(false);
        setReference(response.data);
      }
    } catch (error) {
      setError("Ocorreu um erro, por favor tente mais tarde.");
    }
  });

  const handlePhoneChange = (value, countryData) => {
    setPhoneNumber(value);
    setCountryCode(countryData.dialCode);
  };

  if (error) {
    return <Subtitle>{error}</Subtitle>;
  }

  return (
    <React.Fragment>
      {isCheckout ? (
        isProcessing ? (
          <div style={{ padding: 180 }}>
            <ScaleLoader color="#E43F5A" loading size={150} />
          </div>
        ) : (
          <React.Fragment>
            <Header>Obrigado!</Header>
            <Middle>
              <div>
                <Row>
                  <Title>Referência</Title>
                  <p style={{ fontWeight: 700 }}>{reference.referencia}</p>
                </Row>
                <Row style={{ marginTop: 26 }}>
                  <Title>Detalhes da ordem:</Title>
                  <p style={{ fontWeight: 700 }}>{reference.valor}</p>
                </Row>
              </div>
              <Media>
                <StyledImg
                  fluid={getFluidImageName("mbway_logo")}
                  alt="multibanco logo"
                />
              </Media>
            </Middle>
            <Row>
              <p style={{ paddingBottom: 20 }}>
                Recebera uma notificação na aplicação MB WAY para que confirme o
                pagamento utilizando o seu PIN MB WAY.
              </p>
              <p>
                Após completar esta confirmação, o pagamento está concluido.
              </p>
            </Row>
          </React.Fragment>
        )
      ) : (
        <React.Fragment>
          <div>
            <h1 style={{ marginBottom: 30 }}>Pagamento por MB Way</h1>
            <Row>
              <label htmlFor="phone-number" style={{ marginBottom: 45 }}>
                Insere o número de telemóvel associado à tua conta MB Way para
                podermos continuar
              </label>
              <PhoneInput
                id="phone-number"
                localization={pt}
                country={"pt"}
                isValid={validateNumber}
                value={phoneNumber}
                onChange={handlePhoneChange}
                placeholder="+351 910-123-456"
                enableClickOutside={true}
                regions={[
                  "north-america",
                  "south-america",
                  "central-america",
                  "carribean",
                  "eu-union",
                  "ex-ussr",
                  "ex-yugos",
                  "baltic",
                  "middle-east",
                  "north-africa",
                ]}
              />
            </Row>
          </div>

          <Navigation>
            <Button
              onClick={props.onBack}
              size="large"
              color="black"
              bg="none"
              border="blue"
            >
              voltar
            </Button>
            <Button
              onClick={handleClick}
              size="large"
              color="white"
              bg="orange"
              border="none"
              disabled={!isDisabled}
            >
              proceder
            </Button>
          </Navigation>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

MBWayComponent.defaultProps = {
  amount: 1.0,
  onBack: () => {},
};

MBWayComponent.propTypes = {
  amount: PropTypes.number,
  onBack: PropTypes.func,
};

export default MBWayComponent;
