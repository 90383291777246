import Img from "gatsby-image";
import styled from "styled-components";

export const Container = styled.div`
  @media print {
    width: 600px;
    margin: 0 auto;
  }
`;

export const Card = styled.div``;

export const Header = styled.div`
  color: white;
  font-weight: bold;
  background-color: #0080a7;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #0080a7;
  border-top: none;
`;

export const Media = styled.div`
  display: flex;
  align-items: center;
  height: 140px;
  margin-right: 75px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * + * {
    border-top: 1px solid #0080a7;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  padding: 7px 0;
`;

export const Label = styled.div`
  display: inline-block;
  font-size: 14px;
  line-height: normal;
  color: #060038;
`;

export const Value = styled.div`
  display: inline-block;
  font-size: 14px;
  line-height: normal;
  font-weight: bold;
  color: #060038;
`;

export const StyledImg = styled(Img)`
  width: 80px;
`;

export const Subtitle = styled.div`
  margin: 20px 0 30px 0;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 768px) {
    margin-bottom: 4px;
  }
`;
