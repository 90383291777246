import Button from "@components/button";
import MbReference from "./mb-reference-component";
import PropTypes from "prop-types";
import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import styled from "styled-components";
import { useReactToPrint } from "react-to-print";

const LoaderWrapper = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Navigation = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Subtitle = styled.div`
  margin: 20px 0 30px 0;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

const LoaderExampleInlineCentered = () => (
  <LoaderWrapper>
    <ScaleLoader color="#E43F5A" loading size={150} />
  </LoaderWrapper>
);

const MultibancoComponent = (props) => {
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [isProcessing, setIsProcessing] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState({
    entidade: "",
    referencia: "",
    valor: "",
  });

  const fetchMBReference = async () => {
    const timestamp = new Date().getTime();

    try {
      const result = await axios.post("/api/multibanco-reference", {
        amount: props.amount,
        id: timestamp,
        chave: process.env.GATSBY_EUPAGO_API,
      });

      if (result?.data.error) {
        setError(result.data.error);
      } else {
        setData(result.data);
        setIsProcessing(false);
      }
    } catch (error) {
      setError("Ocorreu um erro, por favor tente mais tarde.");
    }
  };

  React.useEffect(() => {
    fetchMBReference();
  }, [props.amount]);

  if (error) {
    return <Subtitle>{error}</Subtitle>;
  }

  return (
    <React.Fragment>
      <div ref={componentRef}>
        {isProcessing ? (
          <LoaderExampleInlineCentered />
        ) : (
          <MbReference
            valor={data.valor}
            entidade={data.entidade}
            referencia={data.referencia}
            printRef={componentRef}
          />
        )}
      </div>
      <Navigation>
        <Button
          onClick={props.onBack}
          size="large"
          color="black"
          bg="none"
          border="blue"
        >
          voltar
        </Button>

        <Button
          onClick={handlePrint}
          size="large"
          color="white"
          bg="orange"
          border="none"
        >
          Guardar Referência
        </Button>
      </Navigation>
    </React.Fragment>
  );
};

MultibancoComponent.defaultProps = {
  amount: 1.0,
  onBack: () => {},
};

MultibancoComponent.propTypes = {
  amount: PropTypes.number,
  onBack: PropTypes.func,
};

export default MultibancoComponent;
