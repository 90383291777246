import {
  Card,
  Container,
  Label,
  Media,
  Radio,
  StyledImg,
  Subtitle,
} from "./styles";
import PropTypes from "prop-types";
import React from "react";
import { useImage } from "@queries/use-image";

const CardPaymentOption = (props) => {
  const { getFluidImageName } = useImage();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      props.onChange({ target: { value: props.value } });
    }
  };

  return (
    <Container onKeyDown={handleKeyDown}>
      <Card>
        <Label>{props.subtitle}</Label>
        <Media>
          <StyledImg
            fluid={getFluidImageName(props.imageName)}
            alt={props.subtitle}
          />
        </Media>
        <Radio
          tabIndex="-1"
          type="radio"
          name="pricing"
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
        />
      </Card>
      <Subtitle>{props.subtitle}</Subtitle>
    </Container>
  );
};

CardPaymentOption.defaultProps = {
  imageName: "multibanco",
  subtitle: "Multibanco",
  checked: false,
  onChange: () => {},
  value: "multibanco",
};

CardPaymentOption.propTypes = {
  imageName: PropTypes.string,
  subtitle: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default CardPaymentOption;
