import {
  Body,
  Card,
  Container,
  Details,
  Header,
  Label,
  Media,
  Row,
  StyledImg,
  Subtitle,
  Title,
  Value,
} from "./styles";
import PropTypes from "prop-types";
import React from "react";
import { useImage } from "@queries/use-image";

const numberWithSpaces = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const MbReference = (props) => {
  const { getFluidImageName } = useImage();

  return (
    <Container ref={props.printRef}>
      <Title>Obrigado!</Title>
      <Subtitle>
        Aqui tens a referência multibanco relativa ao teu apoio.
      </Subtitle>

      <Card>
        <Header>Pagamento por multibanco ou homebanking</Header>
        <Body>
          <Media>
            <StyledImg
              fluid={getFluidImageName("multibanco")}
              alt="multibanco logo"
            />
          </Media>
          <Details>
            <Row>
              <Label>Entidade</Label>
              <Value>{props.entidade}</Value>
            </Row>
            <Row>
              <Label>Referência</Label>
              <Value>{numberWithSpaces(props.referencia)}</Value>
            </Row>
            <Row>
              <Label>Valor</Label>
              <Value>{parseFloat(props.valor).toFixed(2)}€</Value>
            </Row>
          </Details>
        </Body>
      </Card>
    </Container>
  );
};

MbReference.defaultProps = {
  entidade: "",
  referencia: "",
  valor: "",
  printRef: {},
};

MbReference.propTypes = {
  entidade: PropTypes.string,
  referencia: PropTypes.string,
  valor: PropTypes.string,
  printRef: PropTypes.any,
};

export default MbReference;
