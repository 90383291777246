import EmptySpace from "@components/empty-space/component";
import FullBleed from "@components/full-bleed";
import Layout from "@components/layout";
import Newsletter from "@components/newsletter/newsletter-component";
import Payment from "@sections/payment/component";
import PropTypes from "prop-types";
import React from "react";
import Seo from "@components/seo";
import Sponsors from "@components/sponsors/sponsors-component";
import queryString from "query-string";
import { useImage } from "@queries/use-image";

const isQPpresent = (queryParam, property) => {
  if (queryParam) {
    const result = queryString.parse(queryParam);
    const hasBarProperty = Object.prototype.hasOwnProperty.call(
      result,
      property
    );
    return hasBarProperty;
  }

  return false;
};

function Apoiar(props) {
  const { getFluidImageNameAndExtension } = useImage();
  const seoImage = getFluidImageNameAndExtension("kids-hugs-garden", "jpg");

  const {
    location: { search },
  } = props;

  return (
    <Layout isHeroAvailable={false}>
      <EmptySpace desktop={{ margin: 160 }} mobile={{ margin: 100 }} />

      <Payment showThankYouMessage={isQPpresent(search, "thankyou")} />

      <EmptySpace desktop={{ margin: 160 }} mobile={{ margin: 100 }} />

      <Sponsors />

      <EmptySpace desktop={{ margin: 180 }} mobile={{ margin: 80 }} />

      <FullBleed>
        <Newsletter />
      </FullBleed>

      <Seo
        title="Apoiar o TreeTree2 faz crescer Portugal"
        description="E se Portugal tivesse o Cristiano Ronaldo da Física, da Biologia e de outras áreas da Ciência? Apoiar o TreeTree2 é contribuir para projetos que ajudam as crianças e os jovens a desenvolver todo o seu potencial."
        image={seoImage}
      />
    </Layout>
  );
}

Apoiar.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default Apoiar;
