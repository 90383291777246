import Img from "gatsby-image";
import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

export const Media = styled.div`
  display: inline-flex;
  align-items: center;
  height: 150px;
`;

export const StyledImg = styled(Img)`
  width: 100px;
`;

export const Navigation = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Header = styled.h1`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 768px) {
    margin-bottom: 4px;
  }
`;

export const Middle = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #0080a7;
  border-bottom: solid 1px #0080a7;
  padding: 26px 0;
`;
