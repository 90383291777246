import Button from "@components/button";
import CardPaymentOption from "@sections/payment/card-payment-option/component";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Navigation = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Subtitle = styled.div`
  margin: 20px 0 30px 0;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

const Currency = styled.span`
  color: #e43f5a;
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 768px) {
    margin-bottom: 4px;
  }
`;

const PaymentGridTitle = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

const PaymentCardsGrid = styled.div`
  display: grid;

  grid-template-columns: 160px 160px 160px;
  justify-content: space-between;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-items: start;
    justify-content: normal;
    grid-row-gap: 20px;
  }
`;

const PaymentMethodSelector = (props) => {
  return (
    <React.Fragment>
      <div>
        <Title>Apoia hoje a treebo</Title>
        <Subtitle>
          Vais contribuir com <Currency>{props.selectedDonation}€</Currency>
        </Subtitle>

        <div>
          <PaymentGridTitle>Escolhe um método de pagamento</PaymentGridTitle>
          <PaymentCardsGrid>
            <CardPaymentOption
              autofocus="true"
              imageName="credit_card_logo"
              subtitle="Cartão Débito / Crédito"
              value="cc"
              checked={props.selectedMethod === "cc"}
              onChange={props.onSelect}
            />
            <CardPaymentOption
              imageName="mbway_logo"
              subtitle="MB Way"
              value="mbway"
              checked={props.selectedMethod === "mbway"}
              onChange={props.onSelect}
            />
            <CardPaymentOption
              imageName="multibanco"
              subtitle="Multibanco"
              value="multibanco"
              checked={props.selectedMethod === "multibanco"}
              onChange={props.onSelect}
            />
          </PaymentCardsGrid>
        </div>
      </div>
      <Navigation>
        <Button
          onClick={props.onBack}
          size="large"
          color="black"
          bg="none"
          border="blue"
        >
          voltar
        </Button>
        <Button
          onClick={props.onConfirm}
          size="large"
          color="white"
          bg="orange"
          border="none"
        >
          proceder
        </Button>
      </Navigation>
    </React.Fragment>
  );
};

PaymentMethodSelector.defaultProps = {
  selectedMethod: "cc",
  selectedDonation: null,
  onSelect: () => {},
  onConfirm: () => {},
  onBack: () => {},
};

PaymentMethodSelector.propTypes = {
  selectedMethod: PropTypes.string,
  selectedDonation: PropTypes.number,
  onSelect: PropTypes.func,
  onConfirm: PropTypes.func,
  onBack: PropTypes.func,
};

export default PaymentMethodSelector;
