const ACTIONS = {
  GO_BACK: "go_back",
  GO_FORWARD: "go_forward",
  SELECT_PRE_DEFINED_OPTION: "select_pre_defined_option",
  SELECT_CUSTOM_VALUE: "select_custom_value",
  SELECT_PAYMENT_METHOD: "select_payment_method",
};

const STEPS = {
  donation: 0,
  paymentMethod: 1,
  checkout: 2,
};

const LAST_STEP = STEPS.checkout;

const INITIAL_STATE = {
  step: 0,
  donation: {
    preDefined: null,
    custom: undefined,
    isValid: false,
    error: "",
  },
  paymentMethod: "cc",
};

const handleScenarios = (value) => {
  if (Number.isNaN(Number(value))) {
    return {
      isValid: false,
      error: "Introduza um valor valido",
    };
  }

  if (Number(value) > 1000000) {
    return {
      isValid: false,
      error: "valor máximo: €1,000,000.00",
    };
  }

  return {
    isValid: true,
    error: "",
  };
};

const validateDonation = (preDefined, custom) => {
  if (
    preDefined === null &&
    (custom === undefined || String(custom).length === 0)
  ) {
    return {
      isValid: false,
      error: "",
    };
  }

  return handleScenarios(custom);
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload.method,
      };
    case ACTIONS.GO_BACK:
      return {
        ...state,
        step: state.step - 1 < 0 ? 0 : state.step - 1,
      };
    case ACTIONS.GO_FORWARD:
      return {
        ...state,
        step: state.step + 1 > LAST_STEP ? LAST_STEP : state.step + 1,
      };
    case ACTIONS.SELECT_PRE_DEFINED_OPTION: {
      const x = validateDonation(action.payload.value, "");
      return {
        ...state,
        donation: {
          ...state.donation,
          custom: "",
          preDefined: action.payload.value,
          isValid: x.isValid,
          error: x.error,
        },
      };
    }
    case ACTIONS.SELECT_CUSTOM_VALUE: {
      const x = validateDonation(null, action.payload.value);

      return {
        ...state,
        donation: {
          ...state.donation,
          preDefined: null,
          custom: action.payload.value,
          isValid: x.isValid,
          error: x.error,
        },
      };
    }
  }
};

export { ACTIONS, INITIAL_STATE, STEPS, reducer };
