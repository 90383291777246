import { ACTIONS, INITIAL_STATE, STEPS, reducer } from "./constants";
import {
  Container,
  Content,
  Media,
  Paragraph,
  StyledImg,
  Title,
} from "./styles";
import React, { useReducer } from "react";
import CreditCardComponent from "./credit-card/credit-card-component";
import DonationSelector from "./donation-selector/donation-selector-component";
import MBWay from "./mb-way/mb-way-component";
import MultibancoComponent from "./multibanco/multibanco-component";
import PaymentMethodSelector from "./payment-method-selector";
import PropTypes from "prop-types";
import { useImage } from "@queries/use-image";

const renderCheckout = (paymentMethod, selectedDonation, dispatch) => {
  if (paymentMethod === "multibanco") {
    return (
      <MultibancoComponent
        amount={selectedDonation}
        onBack={() => dispatch({ type: ACTIONS.GO_BACK })}
      />
    );
  } else if (paymentMethod === "cc") {
    return <CreditCardComponent amount={selectedDonation} />;
  } else if (paymentMethod === "mbway") {
    return (
      <MBWay
        amount={selectedDonation}
        onBack={() => dispatch({ type: ACTIONS.GO_BACK })}
      />
    );
  }
};

const renderThankyouMessage = () => {
  return (
    <div>
      <Title>Obrigado!</Title>
      <Paragraph>
        A transação foi concluída com sucesso. Muito obrigado por apoiar esta
        iniciativa. O seu contributo é importante para nós.
      </Paragraph>
    </div>
  );
};

const toFloat = (value) => {
  if (typeof value === "number") {
    return value;
  }

  if (String(value).length === 0) {
    return Number(0).toFixed(2);
  }

  try {
    return Number.isNaN(value) ? 0 : Number(value).toFixed(2);
  } catch (error) {
    return 0;
  }
};

const PaymentComponent = (props) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { getFluidImageName } = useImage();
  const { showThankYouMessage } = props;

  return (
    <Container>
      <Media>
        <StyledImg fluid={getFluidImageName("handshake")} alt="Aperto de mão" />
      </Media>

      <Content>
        {showThankYouMessage ? renderThankyouMessage() : null}
        {state.step === STEPS.donation && !showThankYouMessage && (
          <React.Fragment>
            <DonationSelector
              selectedPreDefinedDonation={state.donation.preDefined}
              selectedCustomDonation={state.donation.custom}
              onCustomDonationChange={(value) =>
                dispatch({
                  type: ACTIONS.SELECT_CUSTOM_VALUE,
                  payload: { value: value },
                })
              }
              onPreDefinedDonationChange={(value) =>
                dispatch({
                  type: ACTIONS.SELECT_PRE_DEFINED_OPTION,
                  payload: { value: value },
                })
              }
              onConfirm={() => dispatch({ type: ACTIONS.GO_FORWARD })}
              isDonationValid={state.donation.isValid}
              errorMessage={state.donation.error}
            />
          </React.Fragment>
        )}

        {state.step === STEPS.paymentMethod && !showThankYouMessage && (
          <PaymentMethodSelector
            selectedMethod={state.paymentMethod}
            selectedDonation={toFloat(
              state.donation.preDefined || state.donation.custom
            )}
            onBack={() => dispatch({ type: ACTIONS.GO_BACK })}
            onConfirm={() => dispatch({ type: ACTIONS.GO_FORWARD })}
            onSelect={(event) => {
              dispatch({
                type: ACTIONS.SELECT_PAYMENT_METHOD,
                payload: { method: event.target.value },
              });
            }}
          />
        )}

        {state.step === STEPS.checkout &&
          !showThankYouMessage &&
          renderCheckout(
            state.paymentMethod,
            toFloat(state.donation.preDefined || state.donation.custom),
            dispatch
          )}
      </Content>
    </Container>
  );
};

PaymentComponent.defaultProps = {
  showThankYouMessage: false,
};

PaymentComponent.propTypes = {
  showThankYouMessage: PropTypes.bool,
};

export default PaymentComponent;
